import { debounce } from '@testd-io/dash';
import camelCaseKeys from 'camelcase-keys';
import firebase from 'firebase/app';
import { StorageKeys } from '../storage';
import { getFromStorage } from '../storage/access';

export enum Collection {
  ORGANIZATIONS = 'Organizations',
  PROJECTS = 'Projects',
  FOLDERS = 'Folders',
  USERS = 'Users',
  INVITATIONS = 'Invitations',
  TEST_CASES = 'TestCases',
  TEST_RUNS = 'TestRuns',
  ENVIRONMENTS = 'Environments',
  TEST_CASE_RUNS = 'TestCaseRuns',
}

interface IWhere {
  path: string | firebase.firestore.FieldPath;
  value: string | string[] | boolean | null | Date;
  operator?: Operation;
}

export enum Operation {
  EQUAL = '==',
  NOT_EQUAL = '!=',
  IN = 'in',
  GREATER = '>',
  LESS = '<'
}

export const uuidRegex = /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/g;

// For some reason the id is not included when getting the data for the object
// This is a helper function to rebuild the object
async function resolveDocObject<TData>(
  doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
  options?: { stopPaths?: string[]}
): Promise<TData | null> {
  const snakeData = await doc.data();
  const data = camelCaseKeys<firebase.firestore.DocumentData>(snakeData ?? {}, {
    exclude: [uuidRegex],
    deep: true,
    stopPaths: options?.stopPaths,
  });
  if (!data) return null;
  data.id = doc.id;
  return data as TData;
}
type TDocument = {
  id: string;
};

interface IGetDocumentsWithSnap<TData> {
  collection: Collection;
  where?: IWhere | Array<IWhere>;
  onSnap: (item: Array<TData>) => void;
  orderBy?: { key: string; descending?: boolean };
  limit?: number;
  skipOrgInjection?: boolean;
  options?: {
    stopPaths?: string[]
  }
}

type FireStoreCollectionQuery =
  | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
  | firebase.firestore.Query<firebase.firestore.DocumentData>;

type CurrentlyQuerying = Record<
  Collection,
  Record<string, firebase.Unsubscribe | undefined>
>;
class FirebaseDB {
  private static instance: FirebaseDB;

  private currentlyQuerying: CurrentlyQuerying;

  private constructor() {
    this.currentlyQuerying = {} as CurrentlyQuerying;
  }

  static getInstance() {
    if (!FirebaseDB.instance) {
      FirebaseDB.instance = new FirebaseDB();
    }
    return FirebaseDB.instance;
  }

  reset = () => {
    Object.values(this.currentlyQuerying).forEach((collection) =>
      Object.values(collection ?? {}).forEach((unsub) => unsub?.())
    );
    FirebaseDB.instance = new FirebaseDB();
    this.currentlyQuerying = {} as CurrentlyQuerying;
  };

  updateDocument = async <TPayload extends TDocument>(
    collection: Collection,
    { id, ...payload }: TPayload
  ) => {
    await firebase.firestore().collection(collection).doc(id).update(payload);
    return { ...payload, id };
  };

  debounceUpdate = debounce(({ collection, id, update }) => {
    this.updateDocument(collection, { id, ...update });
  }, 750);

  isCurrentlyQuerying = (collection: Collection, id: string) => {
    const currentCollection = this.currentlyQuerying[collection] ?? {};
    return currentCollection[id];
  };

  removeFromCurrentlyQuerying = (collection: Collection, id: string) => {
    const current = { ...(this.currentlyQuerying[collection] ?? {}) };
    delete current[id];
    this.currentlyQuerying[collection] = current;
  };

  addToCurrentlyQuerying = (
    collection: Collection,
    id: string,
    unSubFunc: firebase.Unsubscribe
  ) => {
    const current = this.currentlyQuerying[collection] ?? {};
    const unSub = () => {
      unSubFunc();
      this.removeFromCurrentlyQuerying(collection, id);
    };
    this.currentlyQuerying[collection] = { ...current, [id]: unSub };
  };

  getDocumentWithSnap = <TData>(
    collection: Collection,
    id: string,
    onSnap: (item: TData) => void,
    options: {
      stopPaths?: string[]
    } | undefined = undefined,
  ) => {
    let unSub = this.isCurrentlyQuerying(collection, id);
    if (unSub) {
      return unSub;
    }
    unSub = firebase
      .firestore()
      .doc(`${collection}/${id}`)
      .onSnapshot(async (snap) => {
        const resolved = await resolveDocObject<TData>(snap, options);
        onSnap(resolved as TData);
      });
    this.addToCurrentlyQuerying(collection, id, unSub);
    return unSub;
  };

  getDocumentsWithSnap = async <TData>({
    collection,
    where,
    onSnap,
    orderBy,
    limit,
    skipOrgInjection,
    options,
  }: IGetDocumentsWithSnap<TData>) => {
    const jsonOrgId = getFromStorage(StorageKeys.ORG_UUID) ?? '';
    const orgId = JSON.parse(jsonOrgId as string);

    const initialWhere = skipOrgInjection
      ? []
      : ([
          { path: 'OrgId', value: orgId, operator: Operation.EQUAL },
        ] as Array<IWhere>);

    const whereArray = initialWhere.concat(where ?? []);
    let collectionRef: FireStoreCollectionQuery = firebase
      .firestore()
      .collection(collection);

    collectionRef = whereArray.reduce<FireStoreCollectionQuery>(
      (acc, { path, operator, value }: IWhere) => acc.where(path, operator || Operation.EQUAL, value),
      collectionRef
    );
    if (orderBy) {
      collectionRef = collectionRef.orderBy(
        orderBy.key,
        orderBy.descending ? 'desc' : 'asc'
      );
    }
    if (limit) {
      collectionRef = collectionRef.limit(limit);
    }
    collectionRef.onSnapshot(async (snap) => {
      const items: Array<Promise<TData | null>> = [];
      snap.forEach((doc) => {
        const item = resolveDocObject<TData>(doc, options);
        items.push(item);
      });
      const resolved = await Promise.all(items);
      onSnap(resolved.filter((item) => !!item) as Array<TData>);
    });
  };
}

const instance = FirebaseDB.getInstance();
export const {
  debounceUpdate,
  getDocumentWithSnap,
  getDocumentsWithSnap,
  updateDocument,
  reset,
} = instance;
