export enum LocalStorageKeys {
  API_KEY = 'Testd::apiKey',
  AUTH_DOMAIN = 'Testd::authDomain',
  DATABASE_URL = 'Testd::databaseUrl',
  PROJECT_ID = 'Testd::projectId',
  STORAGE_BUCKET = 'Testd::storageBucket',
  MESSAGING_SENDER_ID = 'Testd::messagingSenderId',
  APP_ID = 'Testd::appId',
  MEASUREMENT_ID = 'Testd::measurementId',
  LOG_ROCKET_ID = 'Testd::logRocketId',
  BACKEND_API = 'Testd::backendApi',
  STRIPE_KEY = 'Testd::stripeKey',
  INTERCOM_KEY = 'Testd::intercomKey',
  ALGOLIA_ID = 'Testd::algoliaId',
  AUTHORIZATION = 'Testd::authorization',
  ORG_UUID = 'Testd::orgUuid',
  VIEWED_PROJECTS = 'viewedProjects',
  GRID_VIEW = 'gridView',
  TEST_RUN_SIDE_BAR = 'testRunSideBar'
}
