import { Toast, ToastType } from '@testd-io/components';
import React, { createContext, useEffect, useState } from 'react';

declare interface IToast {
  show: boolean;
  message?: string;
  title?: string;
  type: ToastType;
  autoClose?: false | number;
}
declare interface IToastContext {
  setToast: React.Dispatch<React.SetStateAction<IToast>>;
}

const context = createContext<IToastContext>({} as IToastContext);

const Provider: React.FC = ({ children }) => {
  const [
    { show, message, title, type, autoClose },
    setToast,
  ] = useState<IToast>({
    show: false,
    type: ToastType.INFORMATIONAL,
    autoClose: undefined,
  });
  // Reset toast after each trigger
  useEffect(() => {
    if (show) {
      setToast({ show: false, type });
    }
  }, [show, type]);

  return (
    <context.Provider value={{ setToast }}>
      {children}
      <Toast
        {...{
          show,
          message,
          title,
          type,
          autoClose,
        }}
      />
    </context.Provider>
  );
};

export const ToastContext = {
  Provider,
  Consumer: context.Consumer,
  UseContext: context,
};
