import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import firebase from 'firebase';
import { StorageKeys } from 'src/utils/storage';
import { getFromStorage } from 'src/utils/storage/access';

type IGraphQLClientArgs = {
  setToken: (token: string) => void;
  token?: string;
  organization?: string;
};

function promiseToObservable<T>(promise?: Promise<T>) {
  return new Observable((subscriber) => {
    promise?.then((value) => {
      if (subscriber.closed) return;
      subscriber.next(value);
      subscriber.complete();
    });
  });
}

export const GraphQLClient = ({
  token,
  organization,
  setToken,
}: IGraphQLClientArgs) => {
  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (!graphQLErrors) {
      return new Observable((sub) => {
        sub.next({ errors: undefined });
        sub.complete();
      });
    }

    for (let i = 0; i < graphQLErrors.length; i++) {
      const err = graphQLErrors[i];
      if (err.extensions?.code !== 'AUTH_NOT_AUTHENTICATED') {
        continue;
      }
      const { headers: oldHeaders, response } = operation.getContext();

      if (!response?.headers.get('x-expired-token')) {
        continue;
      }
      const promise = firebase.auth().currentUser?.getIdToken(true);
      // eslint-disable-next-line consistent-return
      return promiseToObservable<string>(promise).flatMap((newToken) => {
        setToken(newToken as string);
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${newToken}`,
          },
        });
        return forward(operation);
      });
    }

    window.console.error('[GraphQL error]: ', {
      errors: graphQLErrors,
      queryName: operation.operationName,
    });
    return new Observable((sub) => {
      sub.next({ errors: graphQLErrors });
      sub.complete();
    });
  });

  const httpLink = createHttpLink({
    uri: getFromStorage(StorageKeys.BACKEND_API) ?? '',
  });

  const authLink = (token?: string, organization?: string) =>
    setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: token || '',
        ...(organization && { 'org-id': organization }),
      },
    }));

  return new ApolloClient({
    link: from([authLink(token, organization), errorLink, httpLink]),
    cache: new InMemoryCache(),
  });
};
