import { useCallback, useContext, useRef } from 'react';
import { StorageContext } from 'src/contexts/StorageContext.container';
import { TStorageKeys } from '../storage';

type JSONPrimitive = string | number | boolean | null;
type JSONObject = { [member: string]: JSONValue };
type JSONArray = JSONValue[];
type JSONValue = JSONPrimitive | JSONObject | JSONArray;

export const useStorage = (key?: TStorageKeys) => {
  const keyRef = useRef(key);
  keyRef.current = key;
  const { value: contextValue, setItem, removeItem, clear } = useContext(
    StorageContext.UseContext
  );
  const parseValue = () => {
    if (!key) {
      return undefined;
    }
    try {
      // TODO: remove force unwrap and use a isString helper to fix type error
      return contextValue[key] && JSON.parse(contextValue[key]!);
    } catch (e) {
      window.console.error(e);
    }
    return contextValue[key] || undefined;
  };

  const setValue = useCallback(
    (newValue: JSONValue) => {
      if (!keyRef.current) return;
      const stringified = JSON.stringify(newValue);
      if (stringified === contextValue[keyRef.current]) {
        return;
      }
      setItem({ key: keyRef.current, value: stringified });
    },
    [setItem, contextValue]
  );

  const removeValue = () => {
    if (!key) return;
    removeItem(key);
  };
  return {
    setValue,
    value: parseValue(),
    removeValue,
    clear,
  };
};
