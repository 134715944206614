import { emailRegex, passwordRegex } from '@testd-io/components';
import jwt from 'jsonwebtoken';
import { TestRunStatus, UserRole } from 'src/graphql/generated/backend/graphql';
import { IOrderItem } from 'src/types';
import { LocalStorageKeys } from '../storage/local-storage-keys';

export function missingArgumentPromise<T>() {
  return new Promise<T>((_, reject) =>
    reject(new Error('Required argument missing'))
  );
}

export interface IToken {
  orgId: string;
  orgRole: UserRole;
  user_id: string;
  email: string;
  email_verified: boolean;
  invitationId?: string | null;
}

export const decodeToken = (token: string) => {
  const decoded = jwt.decode(token ?? '');
  return decoded as IToken;
};

export const emailValidation = {
  value: emailRegex,
  message: 'Please enter a valid email address.',
};

export const passwordValidation = {
  value: passwordRegex,
  message:
    'Password must be a minimum of 8 characters, include at least 1 uppercase, 1 lowercase, 1 number, and 1 special character.',
};

export const zipValidation = {
  value: /^[0-9]{5}(?:-[0-9]{4})?$/,
  message: 'Zip/Postal Code must be 5 or 9 digits in length.',
};

export const isProd = () => localStorage.getItem(LocalStorageKeys.APP_ID) === 'testd-prod';

interface IGetSortedTestCaseIdsMap {
  testRunInformation: Record<
    string,
    {
      assignee: string;
      status: TestRunStatus;
      testCaseRunId?: string | undefined;
    }
  >;
  orderedTestCases: IOrderItem[] | null;
}

export const getSortedTestCaseIdsMap = ({
  testRunInformation,
  orderedTestCases,
}: IGetSortedTestCaseIdsMap) => {
  if (!orderedTestCases) {
    return testRunInformation;
  }
  return orderedTestCases.reduce((acc, testCase) => {
    if (testRunInformation[testCase.id]) {
      acc[testCase.id] = testRunInformation[testCase.id];
    }
    return acc;
  }, {} as typeof testRunInformation);
};

export const envVarRegex = new RegExp(/{{\$[a-zA-Z_0-9]+}}/g);

export const extractVariablesFromString = (str: string) => {
  const vars = str.match(envVarRegex) ?? [];
  return new Set(vars.map(variable => variable.replace(/[{{$}}]/g, '')));
}