import * as React from 'react';
import { createContext, useCallback, useEffect, useState } from 'react';
import { StorageKeys, TStorageKeys } from '../utils/storage';
import { getFromStorage, getStorageForKey } from '../utils/storage/access';

declare interface IStorageItem {
  key: TStorageKeys;
  value: string;
}
declare type TStorageItem = {
  [key in TStorageKeys]: string | null;
};
export type IStorageContext = {
  value: TStorageItem;
  setItem: (item: IStorageItem) => void;
  removeItem: (key: TStorageKeys) => void;
  clear: () => void;
};

const setStorage = (item: IStorageItem) => {
  getStorageForKey(item.key).setItem(item.key, item.value);
};

const removeFromStorage = (key: TStorageKeys) => {
  getStorageForKey(key).removeItem(key);
};

const clearStorage = () => {
  window.sessionStorage.clear();
};

const context = createContext({} as IStorageContext);

export const Provider: React.FC<{ bootstrap?: () => void }> = ({
  bootstrap,
  children,
}) => {
  const [value, setValue] = useState({} as TStorageItem);
  const [configsLoaded, setConfigsLoaded] = useState(false);

  useEffect(() => {
    if (configsLoaded) return;

    const currentValue = Object.values(StorageKeys).reduce((acc, key) => {
      acc[key as TStorageKeys] = getFromStorage(key);
      return acc;
    }, {} as TStorageItem);

    fetch(`${window.origin}/config.json`)
      .then((res) => res.json())
      .then((data) => {
        (Object.entries(data) as Array<
          [keyof typeof StorageKeys, string | null]
        >).map(([key, configValue]) =>
          setStorage({ key: StorageKeys[key], value: configValue as string })
        );
        setValue({ ...currentValue, ...data });
        bootstrap?.();
        setConfigsLoaded(true);
      });
  }, [setValue, setConfigsLoaded, configsLoaded, bootstrap]);

  const setItem = useCallback(
    (item: IStorageItem) => {
      setValue((currentValue) => ({ ...currentValue, [item.key]: item.value }));
      setStorage(item);
    },
    [setValue]
  );

  const removeItem = (key: TStorageKeys) => {
    removeFromStorage(key);
    setValue((values) => ({ ...values, [key]: undefined }));
  };

  const clear = useCallback(() => {
    setValue({} as TStorageItem);
    clearStorage();
  }, []);
  return (
    <context.Provider
      value={{
        value,
        setItem,
        removeItem,
        clear,
      }}
    >
      {configsLoaded && children}
    </context.Provider>
  );
};

export const StorageContext = {
  Provider,
  Consumer: context.Consumer,
  UseContext: context,
};
