import { FC } from 'react';
import App from './App.component';
import { useDecodedToken } from './contexts/user/hooks/useDecodedToken';
import { useStorage } from './utils/hooks/use-storage';
import { StorageKeys } from './utils/storage';

declare global {
  interface Window {
    __TESTD__: {
      buildId: string;
      nukeRemoteConfigs?: () => void;
    };
    Intercom: (cmd: string, options?: any) => void;
  }
}

const AppContainer: FC = () => {
  const { value: token, setValue: setToken } = useStorage(
    StorageKeys.AUTHORIZATION
  );
  const decodedToken = useDecodedToken();
  window.__TESTD__ = { buildId: process.env.REACT_APP_BUILD_ID || '' };

  return (
    <App token={token} organization={decodedToken?.orgId} setToken={setToken} />
  );
};

export default AppContainer;
