import { TStorageKeys } from '..';
import { SessionStorageKeys } from '../session-storage-keys';

export const getStorageForKey = (key: TStorageKeys) => {
  if (Object.values(SessionStorageKeys).includes(key as SessionStorageKeys)) {
    return window.sessionStorage;
  }
  return window.localStorage;
};

export const getFromStorage = (key: TStorageKeys) =>
  getStorageForKey(key).getItem(key);
