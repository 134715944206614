// Needed so that the above import works
// eslint-disable-next-line import/no-duplicates
import 'firebase/analytics';
import firebase from 'firebase/app';
// eslint-disable-next-line import/no-duplicates
import 'firebase/auth';
import LogRocket from 'logrocket';
import React, { useCallback, useState } from 'react';
import App from './App.container';
import { StorageContext } from './contexts/StorageContext.container';
import { StorageKeys } from './utils/storage';
import { getFromStorage } from './utils/storage/access';

const { auth } = firebase;
export const Root = () => {
  const [bootStrapped, setBootStrapped] = useState(false);
  const bootstrap = useCallback(() => {
    setBootStrapped(true);
    LogRocket.init(getFromStorage(StorageKeys.LOG_ROCKET_ID) || '');
    const config = {
      apiKey: getFromStorage(StorageKeys.API_KEY),
      authDomain: getFromStorage(StorageKeys.AUTH_DOMAIN),
      databaseURL: getFromStorage(StorageKeys.DATABASE_URL),
      projectId: getFromStorage(StorageKeys.PROJECT_ID),
      storageBucket: getFromStorage(StorageKeys.STORAGE_BUCKET),
      messagingSenderId: getFromStorage(StorageKeys.MESSAGING_SENDER_ID),
      appId: getFromStorage(StorageKeys.APP_ID),
      measurementId: getFromStorage(StorageKeys.MEASUREMENT_ID),
    };
    firebase.initializeApp(config);
    firebase.analytics();
    auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .catch((e) => window.console.error(e));
  }, []);
  return (
    <StorageContext.Provider bootstrap={bootStrapped ? undefined : bootstrap}>
      <App />
    </StorageContext.Provider>
  );
};
