import firebase from 'firebase';
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IUser } from 'src/types';
import { Collection, getDocumentWithSnap } from 'src/utils/firestore';
import { decodeToken, IToken } from 'src/utils/helpers';
import { useStorage } from 'src/utils/hooks/use-storage';
import { StorageKeys } from 'src/utils/storage';

declare interface IUserContext {
  authUser?: firebase.User | null;
  updateAuthUser?: React.Dispatch<firebase.User | null>;
  setToken: (token: string) => void;
  token?: string;
  decodedToken?: IToken;
  userRecord?: IUser | null;
  resetUserContext: () => void;
  updateUserRecord: React.Dispatch<IUser | null>;
}

const context = createContext<IUserContext>({} as IUserContext);

const Provider: React.FC<{ token: string }> = ({
  children,
  token: propsToken,
}) => {
  const [authUser, updateAuthUser] = useState<firebase.User | null>(null);
  const [userRecord, updateUserRecord] = useState<IUser | null>(null);
  const [token, updateToken] = useState(propsToken);
  const {
    setValue: setOrgUuid,
    removeValue: removeOrgUuid,
    value: storageOrgUuid,
  } = useStorage(StorageKeys.ORG_UUID);
  const [decodedToken, setDecodedToken] = useState<IToken>();
  const { setValue } = useStorage(StorageKeys.AUTHORIZATION);
  const userRef = useRef<firebase.User | null>(authUser);
  userRef.current = authUser;

  const isSubscribed = useRef<undefined | Function>(undefined);

  // unsubscribe if there is no userRecord
  useEffect(() => {
    if (!userRecord && isSubscribed.current) {
      isSubscribed.current();
      isSubscribed.current = undefined;
    }
  }, [userRecord]);

  const subscribeToUser = useCallback(
    (id: string) => {
      isSubscribed.current = getDocumentWithSnap<IUser>(
        Collection.USERS,
        id,
        (data) => {
          updateUserRecord(data);
        }
      );
    },
    [updateUserRecord]
  );

  // This is done specifically for the useEffects
  const userId = authUser?.uid;
  const orgId = decodedToken?.orgId;
  const invitationId = decodedToken?.invitationId ?? 'PLACEHOLDER';

  useEffect(() => {
    // If session OrgId and decodedToken Org are the same
    // return
    if ((storageOrgUuid ?? '') === (orgId ?? '')) {
      return;
    }
    if (orgId) {
      setOrgUuid(orgId);
    } else {
      removeOrgUuid();
    }
  }, [orgId, setOrgUuid, removeOrgUuid, storageOrgUuid]);

  useEffect(() => {
    (async () => {
      const newToken = (await userRef.current?.getIdToken(true)) || '';
      if (newToken) setValue(`Bearer ${newToken}`);
      updateToken(newToken);
      const dt = decodeToken(newToken);
      setDecodedToken(dt);
      // Dont subscribe if user is still in invite
      if (userId && !dt.invitationId) {
        subscribeToUser(userId);
      }
    })();
  }, [userId, subscribeToUser, updateToken, setValue, invitationId]);

  // Needed for invite flow
  useEffect(() => {
    if (!isSubscribed.current && !invitationId && userId) {
      subscribeToUser(userId);
    }
  }, [userId, invitationId, subscribeToUser]);

  const setToken = (newToken: string) => {
    const dt = decodeToken(newToken);
    setDecodedToken({ ...dt });
    updateToken(newToken);
  };
  const resetUserContext = () => {
    setDecodedToken(undefined);
    updateAuthUser(null);
    updateUserRecord(null);
    updateToken('');
  };
  return (
    <context.Provider
      value={{
        authUser,
        updateAuthUser,
        setToken,
        token,
        decodedToken,
        userRecord,
        resetUserContext,
        updateUserRecord,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const UserContext = {
  Provider,
  Consumer: context.Consumer,
  UseContext: context,
};
