import React from 'react';
import { CircleLoader } from '@testd-io/components';
import './Fallback.scss';

export const AuthenticatedFallBack = () => (
  <div className="Fallback__div">
    <CircleLoader height={200} width={200} />
  </div>
);

export const FallBack = () => <div className="AuthFallback__div" />;
