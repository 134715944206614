import { ApolloProvider } from '@apollo/client';
import '@testd-io/components/build/index.css';
import { FC, lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import './App.scss';
import { ToastContext } from './contexts/ToastContext.container';
import { UserContext } from './contexts/user/UserContext.container';
import { GraphQLClient } from './graphql/client';
import { FallBack } from './pages/fallback';

const SignupInviteContainer = lazy(
  () => import('./pages/signup/SignupInvitation.container')
);
const ExpiredVerification = lazy(() => import('./pages/expired-verification'));
const AuthContainer = lazy(() => import('./pages/auth'));
const AuthRedirect = lazy(
  () => import('./pages/auth-redirect/AuthRedirect.container')
);
const Confirmation = lazy(
  () => import('./pages/confirmation/Confirmation.container')
);
const Signup = lazy(() => import('./pages/signup/Signup.container'));
const SignupSuccess = lazy(
  () => import('./pages/signup-success/SignupSuccess.container')
);
const ForgotPassword = lazy(
  () => import('./pages/forgot-password/ForgotPassword.container')
);
const ResetPassword = lazy(
  () => import('./pages/reset-password/ResetPassword.container')
);
const VerifyEmail = lazy(
  () => import('./pages/verify-email/VerifyEmail.container')
);
const VerifyEmailChange = lazy(
  () => import('./pages/verify-email-change/VerifyEmailChange.container')
);

interface IAppProps {
  setToken: (newValue: string) => void;
  token?: string;
  organization?: string;
}
const App: FC<IAppProps> = ({ token, organization, setToken }) => (
  <ApolloProvider client={GraphQLClient({ token, organization, setToken })}>
    <UserContext.Provider token={token ?? ''}>
      <ToastContext.Provider>
        <Router>
          <Suspense fallback={<FallBack />}>
            <Switch>
              <Route exact path="/auth" component={AuthRedirect} />
              <Route
                exact
                path="/expired-verification/:userId"
                component={ExpiredVerification}
              />
              <Route exact path="/invite/:code" component={Confirmation} />
              <Route
                exact
                path="/invite/new/:code"
                component={SignupInviteContainer}
              />
              <Route path="/sign-in/:code" component={Confirmation} />
              <Route
                exact
                path="/signup/success/:userId"
                component={SignupSuccess}
              />
              <Route exact path="/signup" component={Signup} />
              <Route path="/login" render={() => <Redirect to="/" />} />
              <Route path="/verify-email/:oobCode" component={VerifyEmail} />
              <Route
                path="/verify-updated-email/:token"
                component={VerifyEmailChange}
              />
              <Route
                path="/verification-needed/:userId"
                component={SignupSuccess}
              />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route
                path="/reset-password/:oobCode"
                component={ResetPassword}
              />
              <Route path="/" component={AuthContainer} />
            </Switch>
          </Suspense>
        </Router>
      </ToastContext.Provider>
    </UserContext.Provider>
  </ApolloProvider>
);

export default App;
